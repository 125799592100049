import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceOutsourcingOfferCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <div className="lead text-muted">
              <h1 className="seo-span text-success">
              Het outsourcen van software naar Polen
              </h1>
              <br />
              verheft je bedrijf naar een volgend niveau
            </div>
            <a
              href="#contact-partial"
              data-form-category="outsourcing"
              className="btn btn-success my-2"
              id="software-outsourcing-more-btn"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.FreeConsultation, 0, 500)
              }}
            >
              Krijg een gratis consult
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="seo-p">
          Ons 
            <h2 className="seo-span">nearshore ontwikkelingsteam</h2> staat klaar om aan jouw toepassing te beginnen 
          (waaronder het vastleggen van de vereisten en de werkzaamheden). 
          Het <h2 className="seo-span">offshore outsourcing</h2> helpt je project ontwikkelen zonder nieuwe werknemers in dienst te 
          nemen en je betaalt alleen voor wat je ontvangt. Door te 
            <h3 className="seo-span">outsourcen naar Polen </h3>
          kan je 30% kosten besparen en het speciale team van Zaven vormt een integraal 
          onderdeel van je bedrijf. 
          </div>
            <p className="text-sm text-muted">
          De beste specialisten werken in ons <strong className="seo-regular">nearshore/offshore outsourcing</strong> team: 
          ontwikkelaars op junior en senior niveau; QA-testers, productiemanagers, 
          bedrijfsanalisten, scrummasters en toegewijde begeleiders van de klant.
            </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOfferCta
