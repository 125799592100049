import React from 'react'
import Link from '../../common/Link'

const ServiceOutsourcingHireUsCta = () => (
  <section className="partial-cta-mvp">
    <div className="container">
      <p className="lead text-center">
        <strong>Outsourcing met een westerse instelling: </strong>&nbsp;
        <span className="text-success">we zijn je beste partner!</span>&nbsp;
        <br className="d-md-none" />
        <Link
          to="/estimate-app-cost/"
          className="intercom-show btn btn-outline-success d-block d-md-inline-block m-3"
        >
          Krijg een schatting!
        </Link>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingHireUsCta
