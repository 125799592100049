import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faEye,
  faGem,
  faUmbrella,
} from '@fortawesome/pro-light-svg-icons'

const ServiceOutsourcingOffer = () => (
  <section className="service-outsourcing py-5 bg-faded">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h4 className="seo-h2 text-sm-center text-md-right mt-0">
            Time & materials
            <br />
            prijs model
          </h4>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group mb-5">
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faEye}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>TRANSPARANTIE</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Dankzij maandelijkse betalingen kan je gemakkelijker financiële 
                controle over het project behouden Zonder verborgen kosten, geen verrassingen

                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faClock}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Toegankelijkheid</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                  Toegewijde ontwikkelaars werken alleen aan jouw project
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faUmbrella}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Veiligheid</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                 De code wordt steeds beveiligd dankzij beveiligingsovereenkomsten 
                 en arbeidsbeleid die in nauwe overeenstemming zijn met beveiligingsprocedures.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faGem}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Kwaliteit</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                  Ons ontwikkelingsteam bestaat uit ervaren, 
                  op commerciële doelen gerichte ontwikkelaars.
                </p>
              </div>
            </div>
          </div>
          <p>
            <strong>Time and Materials</strong> is een voor beide kanten voordelig betaalmodel. 
            De transparantie van dit model zorgt ervoor datde klant een grotere controle behoudt 
            op de kosten en slechts voor het daadwerkelijke werk betaalt. Deze begrotingsmethode 
            laat ons toe een vlotte werkstroom te behouden en maakt de financiering van het project in handige termijnen mogelijk.

          </p>
          <p>
          Belangrijkste voordelen van <strong>Time & Materials</strong> zijn:
          </p>
          <ul>
            <li>Vaste kwaliteitscontrole</li>
            <li>Lage risicofactoren </li>
            <li>Gebrek aan bijkomende kosten</li>
            <li>Ondersteuning van de projectleider voor een vlotte werkstroom</li>
          </ul>
          <p>
          Het is een geweldige regeling voor start-ups, die het vaak ontbreekt 
          aan gedetailleerde productspecificaties, 
            <strong className="seo-span"> toepassingsprototypes </strong> en tijdsvereisten.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOffer
