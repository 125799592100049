import React from 'react'

const ServiceOutsourcingZavenDreamTeam = () => (
  <section className="partial-cta-mvp bg-dark">
    <div className="container">
      <p className="lead text-center">
        <strong>Neem jouw Zaven Dream Team in dienst:</strong>{' '}
        <span className="text-success"> snel, efficiënt en doelgericht.</span>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingZavenDreamTeam
