import React from 'react'

const ServiceOutsourcingDevelopmentModels = () => (
  <section className="service-outsourcing py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h4 className="seo-h2 text-sm-center">Ontwikkelingsmodellen</h4>
          <div className="lead my-2 text-sm-center">
          Met outsourcing heb je de keuze uit verschillende 
          ontwikkelingsmodellen voor         
            <h3 className="seo-span">web- en mobiele toepassingen</h3>
            .&nbsp;Wij staan er voor open en we passen ons aan jouw behoeften 
          en jouw product aan. Het enige wat nooit zal veranderen zijn onze hoge standaarden.
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">NDC</span>
            Nearshore <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
          <strong>Een geweldige oplossing voor alle Europese bedrijven.</strong> De leden van 
          ons team kennen Engels en Duits. Vanwege 
                        <h2 classname="seo=span">nearshore outsourcing</h2> en 
          onze westerse manier van denken kunnen we gemakkelijk langdurige 
          zakenrelaties aangaan over de grenzen heen. 
          
          </div>
          <div className="text-sm text-muted">
          Dankzij uitstekende luchtverbindingen van en naar Polen 
          kunnen we regelmatig onze Europese partners ontmoeten. Terwijl jij 
          je toelegt op je bedrijf kan je ons goedkope<h3 className="seo-span"> mobiele en webtoepassingen </h3>
          voor jou laten maken. Ons team bestaat uit een groep getalenteerde en 
          gepassioneerde technische experts die werken met een soepele methodologie. 
          We nodigen je graag uit naar ons bureau in Wrocław - de technologische hoofdstad van Polen. 
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">ODC</span>
            Offshore <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>
              Bestemd voor zakenpartners uit de Verenigde Staten, Canada, Scandinavië, Azië en Australië,
            </strong>
            &nbsp;die deskundig geschoolde en goed opgeleide experts, Engelstalig management, Europese juridische 
            standaarden en beveiligingen en een hoogwaardige code op prijs stellen. 
          </div>
          <div className="text-sm text-muted">
            ODC laat ons samenwerken 
            met klanten uit de hele wereld. <h3 className="seo-span">De ontwikkeling van offshoring in Polen </h3>is erg voordelig voor onze 
            buitenlandse klanten: Polen is lid van de Europese Unie en de Schengenlanden, waardoor de wisselkoers 
            erg stabiel is en de projectkosten gemakkelijk in te schatten zijn. Wereldwijde standaarden, efficiëntie 
            en snelheid maken van ons de ideale langdurige zakenpartner.
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">DDC</span>
            Dedicated <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>Ideaal voor grotere IT-agentschappen</strong>, die een bepaald deel van hun taken moeten outsourcen zonder nieuwe werknemers in dienst te nemen.
          </div>
          <div className="text-sm text-muted">
            Dat model maakt het mogelijk voltijds&nbsp;<strong>een toegewijd expertenteam</strong>, aan te stellen. 
            Onze specialisten kennen de markt en aangezien ze alleen met jouw project werken 
            zullen ze jouw product ook goed begrijpen en precies weten hoe ze het naar een 
            hoger niveau moeten tillen. Het volstaat dat je een van je werknemers aanstelt voor 
            het overbrengen van richtlijnen en het ontvangen van rapporten. Indien je geïnteresseerd 
            bent, stuur ons dan een samenvatting van je project zodat wij een een persoonlijke 
            prijsopgave kunnen maken voor het aanwerven van een toegewijd&nbsp;<h3 className="seo-span">team ontwikkelaars</h3>.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingDevelopmentModels
